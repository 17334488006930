import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "distance-ten"
    }}>{`Distance-ten`}</h1>
    <p>{`This service handles distances between railway stations, measured along the track.`}</p>
    <p>{`It has an open API for looking up distances, and an internal API for data maintenance.`}</p>
    <h2 {...{
      "id": "endpoint"
    }}>{`Endpoint`}</h2>
    <p>{`Get the distance between two stations.`}</p>
    <p>{`Both parameters must be NSR stopPlace IDs for Norwegian railway stations. `}</p>
    <p><inlineCode parentName="p">{`GET`}</inlineCode>{` `}<inlineCode parentName="p">{`https://api.entur.io/distance-ten/{stopPlace1}/{stopPlace2}`}</inlineCode></p>
    <h3 {...{
      "id": "headers"
    }}>{`Headers`}</h3>
    <ul>
      <li parentName="ul">{`ET-Client-Name: `}<inlineCode parentName="li">{`<company> - <application>`}</inlineCode>{` (See Authentication section above)`}</li>
    </ul>
    <h3 {...{
      "id": "responses"
    }}>{`Responses`}</h3>
    <h4 {...{
      "id": "200-ok"
    }}>{`200 OK`}</h4>
    <p>{`The value returned is a single integer representing the distance in TEN between the two stations.
For most practical purposes, 1 TEN == 1 km.`}</p>
    <h4 {...{
      "id": "404-not-found"
    }}>{`404 Not Found`}</h4>
    <p>{`The distance was not found.`}</p>
    <h4 {...{
      "id": "500-internal-server-error"
    }}>{`500 Internal Server Error`}</h4>
    <p>{`An unexpected internal error occured.`}</p>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl \\
    -X GET \\
    -H "ET-Client-Name: somecompany - someapp" \\
    https://api.entur.io/distance-ten/NSR:StopPlace:337/NSR:StopPlace:1
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      